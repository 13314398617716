
export const BACKGROUNDS = [
    ['Test subject', 'Spell: Magic missile', 'Lead coat (heavy armour)'],
    ['Kitchen forager', 'Shield & jerkin (light armour)', 'Cookpots'],
    ['Cage dweller', 'Spell: Be understood', 'Bottle of milk'],
    ['Hedge witch', 'Spell: Heal', 'Incense stick'],
    ['Leatherworker', 'Shield & jerkin (light armour)', 'Shears'],
    ['Street tough', 'Dagger (light, d6)', 'Flask of coffee'],
    ['Mendicant priest', 'Spell: Calm', 'Holy symbol'],
    ['Ale brewer', 'Hireling: Drunken torchbearer', 'Small barrel of ale'],
    ['Beetleherd', 'Hireling: Loyal beetle', 'Pole, 6"'],
    ['Fishermouse', 'Net', 'Needle (light, d6)'],
    ['Blacksmith', 'Hammer (medium, d6/d8)', 'Metal file'],
    ['Wireworker', 'Wire, spool', 'Electric lantern'],
    ['Woodcutter', 'Axe (medium, d6/d8)', 'Twine, roll'],
    ['Bat cultist', 'Spell: Darkness', 'Bag of bat teeth'],
    ['Tin miner', 'Pickaxe (medium, d6/d8)', 'Lantern'],
    ['Trash collector', 'Trash-hook (heavy, d10)', 'Mirror'],
    ['Wall rover', 'Fishhook', 'Thread, spool'],
    ['Merchant', 'Hireling: Pack rat', '20p IOU from noblemouse'],
    ['Raft crew', 'Hammer (medium, d6/d8)', 'Wooden spikes'],
    ['Worm wrangler', 'Pole, 6"', 'Soap'],
    ['Sparrow rider', 'Fishhook', 'Goggles'],
    ['Sewer guide', 'Metal file', 'Thread, spool'],
    ['Prison guard', 'Chain, 6"', 'Spear (heavy, d10)'],
    ['Fungus farmer', 'Dried mushroom (as rations)', 'Spore mask'],
    ['Dam builder', 'Shovel', 'Wooden spikes'],
    ['Cartographer', 'Quill & ink', 'Compass'],
    ['Trap thief', 'Block of cheese', 'Glue'],
    ['Vagabond', 'Tent', 'Treasure map, dubious'],
    ['Grain farmer', 'Spear (heavy, d10)', 'Whistle'],
    ['Message runner', 'Bedroll', 'Documents, sealed'],
    ['Troubadour', 'Musical instrument', 'Mirror'],
    ['Gambler', 'Set of loaded dice', 'Mirror'],
    ['Sap tapper', 'Bucket', 'Wooden spikes'],
    ['Bee keeper', 'Jar of honey', 'Net'],
    ['Librarian', 'Scrap of obscure book', 'Quill & ink'],
    ['Pauper noblemouse', 'Felt hat', 'Perfume'],
];

export const FIRST_NAMES = [
    'Ada',
    'Agate',
    'Agnes',
    'Aloe',
    'April',
    'Azalea ',
    'Bay',
    'Belladonna ',
    'Blossom',
    'Brie ',
    'Brynn',
    'Cherry',
    'Claire',
    'Crocus',
    'Dahlia ',
    'Daisy',
    'Else',
    'Emerald',
    'Erin',
    'Grace',
    'Gwendoline',
    'Hazel',
    'Heather',
    'Hette',
    'Holly',
    'Hyacinth',
    'Iris',
    'Juniper',
    'Lavender',
    'Lily',
    'Magnolia ',
    'Marigold',
    'Marjoram ',
    'Myrtle',
    'Odette',
    'Olive',
    'Opal',
    'Pearl',
    'Pepper',
    'Poppy',
    'Rosemary',
    'Rue',
    'Saffron ',
    'Sandy',
    'Sassafras ',
    'Shale',
    'Susan',
    'Thistle',
    'Violet',
    'Willow',
    'Alder',
    'Ambrose',
    'Anise',
    'Annotto ',
    'August',
    'Avens',
    'Basil',
    'Beryl',
    'Birch',
    'Boldo ',
    'Bill',
    'Burdock',
    'Butter',
    'Cassia',
    'Chicory',
    'Clive',
    'Colby',
    'Dill',
    'Eared',
    'Edmund',
    'Elmer',
    'Ernest',
    'Fennel',
    'Festus',
    'Francis',
    'Gil',
    'Hawthorn ',
    'Heath',
    'Horatio',
    'Jack',
    'Jasper',
    'Konrad',
    'Larkspur',
    'Laurel',
    'Lorenz',
    'Mace',
    'Oliver',
    'Orin',
    'Reepicheep',
    'Rowan',
    'Simon',
    'Sorrel',
    'Stilton',
    'Tarragon ',
    'Warren ',
    'Wattle',
    'Wattle',
    'Whitacre',
    'Wormwood',
    'Yarrow',
];

export const LAST_NAMES = [
    'Baiter',
    'Black',
    'Buckthorne',
    'Burley',
    'Butterball',
    'Catreizen',
    'Danger',
    'Deerider',
    'Grant',
    'Halva',
    'Maker',
    'Pipp',
    'Ratschlappe',
    'Seedfall',
    'Snow',
    'Summerholme',
    'Tunneler',
    'White',
    'Winterholme',
    'Witter',
];

export const BIRTH_SIGNS = [
    ['Star', 'Brave / Reckless'],
    ['Wheel', 'Industrious / Unimaginative'],
    ['Acorn', 'Inquisitive / Stubborn'],
    ['Storm', 'Generous / Wrathful'],
    ['Moon', 'Wise / Mysterious'],
    ['Mother', 'Nurturing / Worrying'],
];

export const COAT_COLORS = [
    'Chocolate',
    'Black',
    'White',
    'Tan',
    'Grey',
    'Blue',
];

export const COAT_PATTERNS = [
    'solid',
    'brindle',
    'patchy',
    'banded',
    'marbled',
    'flecked',
];

export const PHYSICAL_DETAIL = [
    'Scarred body',
    'Corpulent body',
    'Skeletal body',
    'Willowy body',
    'Tiny body',
    'Massive body',
    'War paint',
    'Foreign clothes',
    'Elegant clothes',
    'Patched clothes',
    'Fashionable clothes',
    'Unwashed clothes',
    'Missing ear',
    'Lumpy face',
    'Beautiful face',
    'Round face',
    'Delicate face',
    'Elongated face',
    'Groomed fur',
    'Dreadlocks',
    'Dyed fur',
    'Shaved fur',
    'Frizzy fur',
    'Silky fur',
    'Night black eyes',
    'Eyepatch',
    'Blood red eyes',
    'Wise eyes',
    'Sharp eyes',
    'Luminous eyes',
    'Cropped tail',
    'Whip-like tail',
    'Tufted tail',
    'Stubby tail',
    'Prehensile tail',
    'Curly tail',
];
